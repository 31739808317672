import React, { useEffect, Fragment, useState, useMemo, useRef, useCallback } from 'react';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import PropTypes from 'prop-types';
import { noop, throttle, debounce } from 'lodash';
import { Canvas, useThree } from 'react-three-fiber';
import {
  eventBus,
  globalEventsKeys,
  settingsManager,
  cacheManager,
  cacheKeys,
  utils,
  syncModelCompareCameras,
  getModelMovement,
  modelTypes,
  checkRender,
  setModelPosition,
  getCamerasForCompareSync,
  PluginState,
  getJawByObjectKey,
  storeCommunicationService,
  getCameraPosByCaseType,
} from '@web-3d-tool/shared-logic';
import { MultiTouchControlsOrthographic } from '@web-3d-tool/three-multitouch-controls';
import { ZoomConstants } from '@web-3d-tool/shared-logic/src/constants/camera.constants';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { explorers } from '@web-3d-tool/shared-logic/src/constants/menuTypes360.constants';
import FPSStats from 'react-fps-stats';
import { NIRI, IOC } from '@web-3d-tool/shared-logic/src/constants/tools.constants';
import { Camera, Controls, useCamera } from './Camera';
import Scene from './Scene';
import stylesViewer from './Renderer.module.css';
import styles360 from './Renderer360.module.css';

const Renderer = (props) => {
  const {
    isAOHS,
    cameraProps,
    meshes,
    ignoreModels,
    getThreeJSObjects,
    onCameraMove,
    onCameraStopMoving,
    geometries,
    onTap2Fingers,
    onDoubletap2Fingers,
    id,
    onMount,
    resetCameraRotationOnUpdate,
    isModelCompareActive,
    isModelSynced,
    numberOfItems,
    isSplittedViewWithSidePluginActive,
    imageFrameDimentions,
    isModelCompareInDifferentMode,
    rendererInRowIndex,
    split,
    isOnLanding,
    modelType,
    isLuminaScan,
    bite,
  } = props;

  const isResto = (modelType) => utils.getModelCaseType(modelType) === 'resto';
  const { ORIGIN, COMPARE } = modelTypes;
  const is360 = utils.getIs360HubEnabled();
  const styles = is360 ? styles360 : stylesViewer;

  // states
  const [modelRendered, setModelRendered] = useState(false);
  const [jawsChanged, setJawsChanged] = useState(false);
  const [isReviewToolActive, setIsReviewToolActive] = useState(false);
  const [currentExplorer, setCurrentExplorer] = useState(null);
  const [isEnlarged, setIsEnlarged] = useState(false);

  // refs
  const threeProps = useRef({});
  const isModelCompareActiveAndSynced = useRef(false);
  const rendererSubscriprions = useRef([]);
  const reviewTools = useRef({
    [IOC.id]: PluginState.Inactive,
    [NIRI.id]: PluginState.Inactive,
  });

  // memos
  const isDebugEnabled = useMemo(() => settingsManager.getConfigValue(configValues.debug) === 'true', []);
  const zoomParameter = useMemo(() => {
    switch (true) {
      case isAOHS && isOnLanding:
        return ZoomConstants.LANDING_PAGE_MODEL_ZOOM_AOHS;
      case isOnLanding:
        return ZoomConstants.LANDING_PAGE_MODEL_ZOOM;
      case is360 && !isModelCompareActive:
        return ZoomConstants.DEFAULT_MODEL_ZOOM_LAYOUT360;
      case isModelCompareActive:
        return isReviewToolActive
          ? ZoomConstants.COMPARE_MODE_MODEL_ZOOM_SPLIT_SCREEN
          : ZoomConstants.COMPARE_MODE_MODEL_ZOOM;
      default:
        return ZoomConstants.DEFAULT_MODEL_ZOOM;
    }
  }, [isAOHS, isOnLanding, is360, isModelCompareActive, isReviewToolActive]);

  const {
    camera,
    cameraControls,
    cameraRef,
    cameraControlsRef,
    resetCameraPosition,
    zoomCameraTo,
    setStaticMode,
  } = useCamera(cameraProps.position, cameraProps.up, zoomParameter);

  const callAnimationChangeComplette = throttle(() => {
    eventBus.raiseEvent(globalEventsKeys.ANIMATION_CHANGE_COMPLETE);
  }, utils.getValueByBrowser());

  const animate = useCallback(
    (time) => {
      const isUpdate = TWEEN.update(time);
      const { gl, camera, scene } = threeProps.current;
      if (isUpdate && camera) {
        gl.render(scene, camera);
        requestAnimationFrame((time) => animate(time));
        eventBus.raiseEvent(globalEventsKeys.ANIMATION_CHANGE_EVENT, { camera });
        callAnimationChangeComplette();
      }
    },
    [callAnimationChangeComplette]
  );

  const handleCameraPositionRecord = ({ camera, isWheelZoom, zoomParam = 1 } = {}) => {
    if (camera) {
      const { position, rotation, up, zoom } = camera;
      storeCommunicationService.updateStore({ lastCameraPositions: { position, rotation, up } });

      if (isWheelZoom) {
        const { lastCameraPositions } = storeCommunicationService.getStore();
        storeCommunicationService.updateStore({
          lastCameraPositions: { ...lastCameraPositions, zoom: zoom * zoomParam },
        });
      }
    }
  };

  const getAndSyncMainCamera = useCallback(
    ({ modelType, shouldSetZoom }) => {
      const compareCameraObjects = getCamerasForCompareSync();

      const originCamera = Object.values(compareCameraObjects).find((camObjects) => camObjects.modelType === modelType)
        ?.camera;

      syncModelCompareCameras({
        currentActiveCamera: originCamera || camera,
        isModelCompareInDifferentMode,
        shouldSetZoom,
        isResto,
      });
    },
    [camera, isModelCompareInDifferentMode]
  );

  const shiftModels = useCallback(
    ({ shiftBack, isEnlarged, withTransition = true } = {}) => {
      const compareCameraObjects = getCamerasForCompareSync();

      Object.values(compareCameraObjects).forEach((cameraObjects) => {
        const { camera, modelType } = cameraObjects;
        const { renderedModels = {} } = storeCommunicationService.getStore();
        const { maxModelMovement } = renderedModels[modelType] || {};

        if (camera && (!maxModelMovement || shiftBack)) {
          const coords = { x: camera.position.x, y: camera.position.y, z: camera.position.z };

          const modelMovement = getModelMovement({
            isEnlarged: isEnlarged,
            imageFrameDimentions,
            isResto: shiftBack ? !isResto(modelType) : isResto(modelType),
          });

          const maxMovement = camera.position.x + modelMovement / (isEnlarged ? 2 : 1);

          if (!shiftBack && !isEnlarged) {
            renderedModels[modelType].maxModelMovement = modelMovement;
          }

          if (withTransition) {
            new TWEEN.Tween(coords)
              .to({ x: maxMovement }, 500)
              .easing(TWEEN.Easing.Quadratic.Out)
              .onUpdate(() => {
                camera.position.set(coords.x, camera.position.y, camera.position.z);
              })
              .start();
          } else {
            camera.position.set(maxMovement, camera.position.y, camera.position.z);
          }
        }
      });
    },
    [imageFrameDimentions]
  );

  const storeChangeHandler = debounce(({ detail }) => {
    let isAnyReviewToolPluginsActive = false;

    const reviewToolsIds = [IOC.id, NIRI.id];
    const { plugins, plugin360Parameters, isEnlarged } = detail;
    const { currentExplorer, prevExplorer } = plugin360Parameters || {};

    reviewToolsIds.forEach((id) => {
      const { pluginState, pluginPreviousState } = plugins[id] || {};
      if (pluginState !== pluginPreviousState) {
        reviewTools.current[id] = pluginState;
        isAnyReviewToolPluginsActive = reviewToolsIds.some((pid) => reviewTools.current[pid] === PluginState.Active);
      }
    });

    if (currentExplorer !== prevExplorer) {
      setCurrentExplorer(currentExplorer);
    }

    setIsEnlarged(isEnlarged);
    setIsReviewToolActive(isAnyReviewToolPluginsActive);
  }, utils.getValueByBrowser());

  useEffect(() => {
    const subscriptions = rendererSubscriprions.current;
    if (subscriptions.length === 0 && modelType === ORIGIN) {
      subscriptions.push(
        eventBus.subscribeToEvent(
          globalEventsKeys.MODEL_COMPARE_INITIATED,
          ({ isModelCompareActive, isModelCompared }) => {
            if (isModelCompareActive && isModelCompared) {
              const compareCameraObjects = getCamerasForCompareSync();
              const originCamera = Object.values(compareCameraObjects).find(
                (camObjects) => camObjects.modelType === ORIGIN
              )?.camera;

              const zoomParam = isReviewToolActive
                ? ZoomConstants.COMPARE_MODE_MODEL_ZOOM_SPLIT_SCREEN
                : ZoomConstants.COMPARE_MODE_MODEL_ZOOM;

              handleCameraPositionRecord({ camera: originCamera, isWheelZoom: true, zoomParam });
            }
          }
        ),
        eventBus.subscribeToEvent(globalEventsKeys.CONDITION_NAVIGATION_CHANGED, () => {
          storeCommunicationService.updateStore({ lastCameraPositions: null });
        }),
        eventBus.subscribeToEvent(globalEventsKeys.MODEL_UNLOADED, () => {
          storeCommunicationService.updateStore({ lastCameraPositions: null });
        }),
        eventBus.subscribeToEvent(globalEventsKeys.JAWS_CHANGED, ({ visibilityObject }) => {
          setJawsChanged(visibilityObject);

          const { renderedModels = {} } = storeCommunicationService.getStore();
          Object.values(renderedModels).forEach((renderedModelProps) => {
            renderedModelProps.maxModelMovement = 0;
          });
          storeCommunicationService.updateStore({ renderedModels });
        }),
        eventBus.subscribeToEvent(globalEventsKeys.MODEL_RENDERED, ({ modelType }) => {
          const { renderedModels = {} } = storeCommunicationService.getStore();

          if (!renderedModels[modelType]) {
            renderedModels[modelType] = {};
          }
          renderedModels[modelType].isRendered = true;
          renderedModels[modelType].maxModelMovement = 0;

          storeCommunicationService.updateStore({ renderedModels });
          setModelRendered(true);
        })
      );
    }

    storeCommunicationService.subscribe(storeChangeHandler);

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
      storeCommunicationService.unsubscribe(storeChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (camera && modelRendered) {
      const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT) || [];
      const threeObjects = cacheManager.get(cacheKeys.THREE_OBJECTS);

      const filteredVisibilityObject = visibilityObject.filter((visibilityObject) => {
        if (/upper_jaw|lower_jaw$/.exec(visibilityObject)) {
          const jawName = getJawByObjectKey(visibilityObject);
          return jawName;
        }
        return null;
      });

      filteredVisibilityObject.forEach((jawName) => {
        threeObjects[modelType][jawName].camera = camera;
      });
    }
  }, [camera, modelType, jawsChanged, modelRendered]);

  useEffect(() => {
    handleCameraPositionRecord({ camera });

    if (isModelCompareActive && isReviewToolActive) {
      getAndSyncMainCamera({ modelType: ORIGIN, shouldSetZoom: false });
      shiftModels({ withTransition: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jawsChanged]);

  useEffect(() => {
    isOnLanding && eventBus.raiseEvent(globalEventsKeys.RESET_RENDERING_STAGE);
    resetCameraPosition(meshes);
    setStaticMode(isOnLanding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnLanding]);

  useEffect(() => {
    isModelCompareActiveAndSynced.current = isModelCompareActive && isModelSynced;

    if (!isModelCompareActive) {
      const { renderedModels } = storeCommunicationService.getStore();
      if (renderedModels && renderedModels[COMPARE]) {
        delete renderedModels[COMPARE];
        storeCommunicationService.updateStore({ renderedModels });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModelCompareActive, isModelSynced]);

  useEffect(() => {
    if (is360 && split === 2) {
      if (camera && isModelCompareActive && !isReviewToolActive && currentExplorer !== explorers.ALIGNMENT) {
        const { lastCameraPositions } = storeCommunicationService.getStore();
        if (!!lastCameraPositions) {
          setModelPosition(lastCameraPositions, ORIGIN);
        }
      }
      getAndSyncMainCamera({ modelType: ORIGIN });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelRendered]);

  useEffect(() => {
    if (is360 && split === 2 && isModelCompareActive) {
      if (isReviewToolActive) {
        getAndSyncMainCamera({ modelType });
        shiftModels();
      } else {
        const { renderedModels = {} } = storeCommunicationService.getStore();
        Object.values(renderedModels).forEach((renderedModelProps) => {
          renderedModelProps.maxModelMovement = 0;
        });
        storeCommunicationService.updateStore({ renderedModels });
        resetCameraPosition(meshes, ZoomConstants.COMPARE_MODE_MODEL_ZOOM_SPLIT_SCREEN);
        getAndSyncMainCamera({ modelType: ORIGIN });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewToolActive]);

  useEffect(() => {
    if (is360 && camera && split === 1 && !isOnLanding) {
      if (isEnlarged) {
        shiftModels({ isEnlarged: true });
      } else {
        shiftModels({ shiftBack: true, isEnlarged: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnlarged]);

  useEffect(() => {
    const caseType = utils.getModelCaseType(modelType);
    const cameraPositionByCaseType = getCameraPosByCaseType(caseType, 'front');
    const cameraPositionAcceptable = camera?.position
      .toArray()
      .every((pos, i) => pos === cameraPositionByCaseType.position[i]);
    const shouldAlignJaws =
      cameraPositionAcceptable &&
      !isModelCompareActive &&
      camera &&
      split === 2 &&
      currentExplorer === explorers.GUM_HEALTH;

    if (shouldAlignJaws) {
      resetCameraPosition(meshes);

      const multiplyer = rendererInRowIndex ? -1 : 1;
      const coords = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
      new TWEEN.Tween(coords)
        .to({ ...camera.position, z: 7 * multiplyer }, 500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          camera.position.set(camera.position.x, camera.position.y, coords.z);
        })
        .start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExplorer, isReviewToolActive]);

  if (!meshes) return null;

  const filteredMeshes = meshes.filter(({ modelName }) => !ignoreModels.includes(modelName));

  const onSceneMount = () => {
    zoomCameraTo(geometries);
    onMount();
    setStaticMode(isOnLanding);
  };

  const ImperativeComp = ({ getThreeJSObjects }) => {
    // this component is a shadow component that enable us to use
    // useThree() to be able to pull out the imperative objects and send it to

    const controller = new AbortController();
    const { scene, size, canvas, gl } = useThree();
    const updateCameraZoomOnWidthChange = useCallback(() => {
      let scenesDimensions = cacheManager.get(cacheKeys.SCENES_DIMENSIONS);
      if (!scenesDimensions) {
        scenesDimensions = { [scene.uuid]: size };
        cacheManager.set(cacheKeys.SCENES_DIMENSIONS, scenesDimensions);
        return;
      }
      const prevSceneWidth = scenesDimensions[scene.uuid]?.width;
      if (prevSceneWidth !== size.width) {
        scenesDimensions[scene.uuid] = size;
        cacheManager.set(cacheKeys.SCENES_DIMENSIONS, scenesDimensions);
        zoomCameraTo(geometries);
      }
    }, [scene.uuid, size]);

    const getCameraControls = (scene) => {
      return scene.__objects.find((object) => object instanceof MultiTouchControlsOrthographic);
    };

    const setIsModelRendered = () => {
      const { renderedModels } = storeCommunicationService.getStore();
      if (!renderedModels || !renderedModels[modelType]?.isRendered) {
        const isRendered = checkRender(modelType);

        if (isRendered) {
          eventBus.raiseEvent(globalEventsKeys.MODEL_RENDERED, { modelType });
        }
      }
    };

    useEffect(() => {
      const [camera, group] = scene.children;
      threeProps.current = { scene, size, canvas, gl, camera, cameraControls, modelType };

      getThreeJSObjects &&
        getThreeJSObjects({
          scene,
          camera,
          group,
          size,
          canvas,
          controls: cameraControls || getCameraControls(scene),
          split,
          gl,
          splitSide: rendererInRowIndex,
        });

      const setWindowSize = () => {
        if (isSplittedViewWithSidePluginActive && imageFrameDimentions) {
          const newWidth =
            (window.innerWidth - imageFrameDimentions.width + imageFrameDimentions.drawerWidth) / numberOfItems;
          gl.setSize(newWidth, window.innerHeight);
        } else if (numberOfItems === 2) {
          const newWidth = window.innerWidth / numberOfItems;
          gl.setSize(newWidth, window.innerHeight);
        } else {
          gl.setSize(window.innerWidth, window.innerHeight);
        }
        gl.render(scene, camera);
      };

      const setCurrentSize = () => {
        const currentSize = gl.getSize(new THREE.Vector2());
        gl.setSize(currentSize.width, currentSize.height);
        gl.render(scene, camera);
      };

      window.addEventListener('resize', setWindowSize, { signal: controller.signal });
      document.addEventListener('visibilitychange', setCurrentSize, { signal: controller.signal });
      updateCameraZoomOnWidthChange();
      setIsModelRendered();

      return () => {
        controller.abort();
      };
    }, [canvas, controller, getThreeJSObjects, gl, scene, size, updateCameraZoomOnWidthChange]);

    useEffect(() => {
      requestAnimationFrame((time) => animate(time));
    });

    return <Fragment />;
  };

  const handleChangeControls = (target) => {
    const { isZooming, isPanning } = target.activeManipulation || {};
    const isWheelZoom = !!(isZooming && !isPanning);
    if (isModelCompareActiveAndSynced.current) {
      isWheelZoom
        ? setTimeout(() => {
            // We use setTimeout because we need the camera to
            // complete it's manipulation before re-positioning the loupe.
            syncModelCompareCameras({
              currentActiveCamera: target.camera,
              isModelCompareInDifferentMode,
              isWheelZoom,
              isResto,
            });
          }, 50)
        : syncModelCompareCameras({
            currentActiveCamera: target.camera,
            isModelCompareInDifferentMode,
            isResto,
          });
    }
  };

  return (
    <>
      <Canvas
        invalidateFrameloop
        pixelRatio={window.devicePixelRatio}
        className={isOnLanding ? styles.rendererLanding : styles.renderer}
        gl={{ preserveDrawingBuffer: true }}
        id={id}
      >
        <ImperativeComp getThreeJSObjects={getThreeJSObjects} />

        <Camera
          ref={cameraRef}
          near={1}
          far={1500}
          position={cameraProps.position}
          up={cameraProps.up}
          zoom={zoomParameter}
          resetCameraRotationOnUpdate={resetCameraRotationOnUpdate}
          onResetCameraRotation={() => {
            zoomCameraTo(geometries);
          }}
        >
          <Controls
            ref={cameraControlsRef}
            enabled={true}
            dynamicDampingFactor={0.3}
            staticMoving={true}
            handleTap2Fingers={(controls) => {
              zoomCameraTo(meshes);
              const target = controls;
              onCameraMove({ target });
              onCameraStopMoving({ target });
              onTap2Fingers(controls);
              handleChangeControls(target);
            }}
            handleDoubletap2Fingers={(controls) => {
              resetCameraPosition(meshes);
              const target = controls;
              onCameraMove({ target });
              onCameraStopMoving({ target });
              onDoubletap2Fingers(controls);
              handleChangeControls(target);
            }}
            onChange={({ target }) => {
              onCameraMove({ target });
              handleChangeControls(target);
            }}
            onEnd={({ target }) => {
              onCameraStopMoving({ target });
              const { isZooming, isPanning } = target.activeManipulation || {};
              const isWheelZoom = !!(isZooming && !isPanning);
              handleCameraPositionRecord({ camera: target.camera, isWheelZoom });
            }}
            isStaticMode={isOnLanding}
          />
        </Camera>

        {camera && cameraControls && (
          <Scene meshes={filteredMeshes} onMount={onSceneMount} isLuminaScan={isLuminaScan} bite={bite} />
        )}
      </Canvas>
      {isDebugEnabled && <FPSStats />}
    </>
  );
};

Renderer.propTypes = {
  /**
   * Camera options
   */
  cameraProps: PropTypes.shape({
    /**
     * Camera frustum near plane
     */
    near: PropTypes.number,
    /**
     * Camera frustum far plane
     */
    far: PropTypes.number,
    /**
     * A Vector3 representing the camera's local position
     */
    position: PropTypes.arrayOf(PropTypes.number),
    /**
     * This is used by the lookAt method
     */
    up: PropTypes.arrayOf(PropTypes.number),
  }),
  /**
   * Meshes to render
   */
  meshes: PropTypes.arrayOf(PropTypes.number),
  /**
   * Textures
   */
  textures: PropTypes.arrayOf(PropTypes.object),
  ignoreModels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Enable to get the three js imperative objects
   * e.g: scene, camera, group, etc.
   */
  getThreeJSObjects: PropTypes.func,
  onCameraMove: PropTypes.func,
  onCameraStopMoving: PropTypes.func,
  onTap2Fingers: PropTypes.func,
  onDoubletap2Fingers: PropTypes.func,
};

Renderer.defaultProps = {
  ignoreModels: [],
  onCameraMove: noop,
  onCameraStopMoving: noop,
};

export default Renderer;
