import LocalizedStrings from 'react-localization';
import { logger, utils } from '@web-3d-tool/shared-logic';
import en from './translations/en-US.json';

export const strings = new LocalizedStrings({ en });

export const loadLanguage = async () => {
  const localization = utils.getLocale();
  try {
    if (localization) {
      const selectedLanguageTranslation = await import(`./translations/${localization}.json`);
      strings.setContent({ newLang: selectedLanguageTranslation });
    } else {
      throw new Error('locale not provided!');
    }
  } catch (err) {
    logger
      .error(err)
      .data({ module: 'app shell' })
      .end();
  }
};

export const en_strings = new LocalizedStrings({ en });
