import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImageFrame from '../ImageFrame';
import styles from './ImageFrameManipulation.module.css';
import ContrastBrihgtnessSliders from './Drawers/sliders/contrast-brightness-sliders';

const ImageFrameManipulation = ({
  width,
  height,
  src,
  top,
  left,
  scale,
  onChange,
  isVertical,
  componentToRender,
  id,
  dataBiType,
  defaultBrightness,
  defaultContrast,
  onBrightnessChanged,
  onContrastChanged,
  is360,
  closestPhotoObjectParams,
  isEnlargedFrame,
  type,
  luminaOriginalImageSize,
  elementOriginalImageSize,
}) => {
  const [brightness, setBrightness] = useState(defaultBrightness);
  const [contrast, setContrast] = useState(defaultContrast);

  const handleChangeBrightness = (value) => {
    setBrightness(value);
  };

  const handleChangeContrast = (value) => {
    setContrast(value);
  };

  return (
    <div className={styles.container} style={{ flexDirection: isVertical ? 'row' : 'column-reverse' }}>
      <ContrastBrihgtnessSliders
        width={width}
        defaultBrightness={defaultBrightness}
        defaultContrast={defaultContrast}
        isVertical={isVertical}
        dataBiType={dataBiType}
        onContrastChanged={onContrastChanged}
        onBrightnessChanged={onBrightnessChanged}
        handleChangeBrightness={handleChangeBrightness}
        handleChangeContrast={handleChangeContrast}
      />
      <ImageFrame
        is360={is360}
        width={width}
        height={height}
        src={src}
        brightness={brightness}
        contrast={contrast}
        closestPhotoObjectParams={closestPhotoObjectParams}
        onChange={onChange}
        scale={scale}
        top={top}
        left={left}
        id={id}
        dataBiType={dataBiType}
        isEnlargedFrame={isEnlargedFrame}
        type={type}
        luminaOriginalImageSize={luminaOriginalImageSize}
        elementOriginalImageSize={elementOriginalImageSize}
      />
      {componentToRender}
    </div>
  );
};

ImageFrameManipulation.defaultProps = {
  defaultContrast: 100,
  defaultBrightness: 100,
  isVertical: true,
  onBrightnessChanged: noop,
  onContrastChanged: noop,
};

ImageFrameManipulation.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image height
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The rotation to present the images
   */
  rotation: PropTypes.number,
  /**
   * Set the position of the Sliders to below ImageFrame
   */
  isVertical: PropTypes.bool,
  /**
   * Callback, fired when pinch, zoom, pan changes
   */
  onChange: PropTypes.func,
  /**
   * Image id
   */
  id: PropTypes.string.isRequired,
  /**
   *  defaultBrightnesss
   */
  defaultBrightnesss: PropTypes.number,

  /**
   *  defaultContrast
   */
  defaultContrast: PropTypes.number,
  /**
   *  onBrightnessChanged
   */
  onBrightnessChanged: PropTypes.func,
  /**
   *  onContrastChanged
   */
  onContrastChanged: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool,
};

export default ImageFrameManipulation;
