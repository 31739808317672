import { Vector2, Vector3 } from 'three';
import { cloneDeep } from 'lodash';
import { cacheManager, cacheKeys } from '../cache-manager';
import { extractFile, getZippedObject } from '../unzip-util';
import { default as requestsManager } from '../api-requests';
import { default as logger } from '../logger';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
import {
  getExistedScansObj,
  getImageCenter,
  getImagesBlobsFromZippedData,
  getImagesMetaData,
} from './niri-manager.logic';
import { getClosestPhotoObjectRTH } from './default-scanner-type.logic';
import { getClosestPhotoObjectLumina } from './lumina-scanner-type.logic';
import { eventBus, globalEventsKeys } from '../event-bus';
import { isUpperJawEnable, isLowerJawEnable } from '../../../shared-logic/src/model-logic';
import { logToTimberBI, biMethods } from '../timberLogger';
import { pluginsAvailability } from '../plugins-availability/plugins-availability-service';
import { NIRI, IOC } from '../constants/tools.constants';
import { getImageInfoSource } from './lumina/lumina.common.logic';
import { featureAvaliability } from '../feature-avaliability/feature-avaliability-service';

const { getNGNiriEnabled, getIsEnableNGNiriImageSelectionAlgo } = featureAvaliability;

export const isNiriEnabled = () => {
  const isEVxEnabled =
    pluginsAvailability.getIsPluginEnabled(NIRI.rulesMatrixId) ||
    settingsManager.getConfigValue(configuration.isEVxEnabled) === 'true';
  const isIOCEnabled =
    pluginsAvailability.getIsPluginEnabled(IOC.rulesMatrixId) ||
    settingsManager.getConfigValue(configuration.isIOCEnabled) === 'true';

  return isEVxEnabled || isIOCEnabled;
};

export const getPhotosFileByEnv = async (niriFilePath) => {
  if (niriFilePath) {
    try {
      const progressCB = (percentage) =>
        eventBus.raiseEvent(globalEventsKeys.NIRI_PROGRESS_LOADING_CHANGED, percentage);
      const response = await requestsManager.getNiriFile(progressCB, niriFilePath);
      const arraybufferData = await response.arrayBuffer();
      const zippedObject = await getZippedObject(arraybufferData);
      cacheManager.set(cacheKeys.ZIPPED_DATA, zippedObject);
      return zippedObject;
    } catch (err) {
      logger
        .error('error')
        .data({ module: 'niri-logic', err })
        .end();

      logToTimberBI(
        biMethods.errorReportBiLog({
          object: 'niri-logic',
          code: 'NIRI Loading Error',
          description: err.message,
          severity: 'Extracting of NIRI data - failed',
        })
      );
    }
  }
};

export const getMouseVector = (
  x,
  y,
  z,
  camera,
  addSplitScreenWidth,
  width = window.innerWidth,
  height = window.innerHeight
) => {
  const p = new Vector3(x, y, z);
  const vector = p.project(camera);

  vector.x = ((vector.x + 1) / 2) * width + addSplitScreenWidth;
  vector.y = (-(vector.y - 1) / 2) * height;

  return new Vector2(vector.x, vector.y);
};

export const preparePhotosData = async (zippedData, dataJsonCacheKey, imagesMetadataCacheKey) => {
  try {
    const enabledJaws = [];
    const promises = [
      await extractFile(zippedData, 'data.json', 'string'),
      await getImagesBlobsFromZippedData(zippedData),
    ];
    const [dataStr, _imagesBlobs] = await Promise.all(promises);
    const dataJson = JSON.parse(dataStr);
    cacheManager.set(dataJsonCacheKey, dataJson);

    const existedScans = getExistedScansObj(dataJson);

    const jawsObject = {
      upper_jaw: dataJson.jaws.upper_jaw,
      lower_jaw: dataJson.jaws.lower_jaw,
    };
    const isEnableNGNiriImageSelectionAlgo = getNGNiriEnabled() && getIsEnableNGNiriImageSelectionAlgo();

    const jawsPhotosMetadata = { dataJsonCacheKey, imagesMetadataCacheKey };
    const model = cacheManager.get(cacheKeys.MODEL);

    if (isUpperJawEnable(model.objects)) {
      enabledJaws.push('upper_jaw');
    }
    if (isLowerJawEnable(model.objects)) {
      enabledJaws.push('lower_jaw');
    }

    const imageInfoSource = enabledJaws.reduce((acc, jaw) => {
      acc = getImageInfoSource(isEnableNGNiriImageSelectionAlgo, jawsObject, jaw);
      return acc;
    }, 'color');

    enabledJaws.forEach((jaw) => {
      jawsPhotosMetadata[jaw] = getImagesMetaData(dataJson, jaw, existedScans, _imagesBlobs, imageInfoSource);
    });

    const { camera_to_pixel } = dataJson;
    const imagesCenter = getImageCenter(camera_to_pixel);

    logger.time(`extracting ${'data.json'} type ${'string'}`);
    return { existedScans, imagesCenter, jawsPhotosMetadata };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getVectorIntersects = (pointerVector, rayCaster, camera, meshes) => {
  rayCaster.setFromCamera(pointerVector, camera);
  return rayCaster.intersectObjects(meshes);
};

export const getClosestPhotoObject = ({
  jawName,
  intersect,
  imagesCenter,
  jawsPhotosMetadata,
  camera,
  rayCaster,
  eventOrigin,
  meshes,
  modelType,
  viewer360Align2DImages,
  isuminaBestScoreAlgorithmAvaliable,
  isScanOriginLumina,
}) => {
  return isScanOriginLumina
    ? getClosestPhotoObjectLumina({
        jawName,
        intersect,
        jawsPhotosMetadata,
        camera,
        rayCaster,
        eventOrigin,
        meshes,
        viewer360Align2DImages,
        modelType,
        isuminaBestScoreAlgorithmAvaliable,
      })
    : getClosestPhotoObjectRTH({
        jawName,
        intersect,
        imagesCenter,
        jawsPhotosMetadata,
        camera,
        viewer360Align2DImages,
      });
};

export const getNormalizedLoupeCoords = ({
  centerOfLoupeCoords,
  containerWidth,
  containerHeight,
  splittedViewWithSidePluginActive,
  splittedCompareView,
  splittedWindowWidth,
  splitSide,
}) => {
  if (centerOfLoupeCoords) {
    const coordsClone = cloneDeep(centerOfLoupeCoords);
    if (splittedViewWithSidePluginActive && centerOfLoupeCoords.x > splittedWindowWidth) {
      coordsClone.x -= splittedWindowWidth;
    }

    if (splittedCompareView && centerOfLoupeCoords.x > splittedWindowWidth) {
      coordsClone.x += splitSide === 0 ? splittedWindowWidth : -splittedWindowWidth;
    }

    const calculatedContainerWidth =
      splittedViewWithSidePluginActive || splittedCompareView ? splittedWindowWidth : containerWidth;
    const x = (coordsClone.x / calculatedContainerWidth) * 2 - 1;
    const y = -(coordsClone.y / containerHeight) * 2 + 1;

    return { x, y };
  }
  return { x: 0, y: 0 };
};
