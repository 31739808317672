import { utils } from '../utils';
import logger from '../logger';
import { getITRModelWithPolling, fetchNiriFileWithPolling } from './model-fetcher/itr-fetcher.service';
import * as configuration from '../constants/configurationValues.constants';
import { EnvironmentParams, Environments } from './../constants';
import { settingsManager } from '../settings-manager';
import { map } from '../api-service/apiMap';
import { getEnvironmentParams, compileUrl } from '../api-service/apiService-helper';
import { logToTimberBI, biMethods } from '../timberLogger';

const { apiMapKeys } = map;

const { NIRI_ASSET_REFFERENCE_TYPE_NUMBER } = EnvironmentParams;

const getModelUrl = () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  const apiMapKey = apiMapKeys('getModelByType');
  const { baseUrl, url } = getEnvironmentParams(apiMapKey);
  const modelUrl = compileUrl({
    baseUrl,
    url,
    queryParams: {
      orderId,
      fromGenericApi,
      type: 'itr',
    },
  });
  return modelUrl;
};

const niriQueryParams = (orderId) => {
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  return {
    assetReferenceType: NIRI_ASSET_REFFERENCE_TYPE_NUMBER,
    orderId: orderId || settingsManager.getConfigValue(configuration.orderId),
    fromGenericApi,
    type: 'niri',
  };
};

const getNiriFilePath = (orderId) => {
  const niriFilePath = {
    queryParams: niriQueryParams(orderId),
  };
  return niriFilePath;
};

const getNiriFile = async (progressCB, params) => {
  try {
    const { orderId } = params.queryParams || params;
    const isStandalone = utils.isStandaloneMode();
    const apiMapKey = apiMapKeys('getModelByType');
    const niriFilePath = getNiriFilePath(orderId);

    const requestParams = isStandalone
      ? {
          url: `models/${orderId}.niri`,
        }
      : {
          selector: apiMapKey,
          ...niriFilePath,
        };

    return await fetchNiriFileWithPolling({
      requestParams,
      environment: Environments.SCANNER,
      progressCB,
    });
  } catch (error) {
    logger
      .error('No NIRI file path')
      .data({ module: 'AOHSRequests' })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'AOHSRequests',
        code: 'No NIRI file path',
        description: error.message,
        severity: 'Fatal - No NIRI file path',
      })
    );
  }
};

const getModel = async (orderId) => {
  const isStandalone = utils.isStandaloneMode();
  const fromGenericApi = settingsManager.getConfigValue(configuration.fromGenericApi) === 'true';
  const apiMapKey = apiMapKeys('getModelByType');
  const requestParams = isStandalone
    ? {
        url: `models/${orderId}.itr`,
      }
    : {
        selector: apiMapKey,
        queryParams: {
          orderId,
          fromGenericApi,
          type: 'itr',
        },
      };
  return await getITRModelWithPolling(Environments.EUP, requestParams);
};

const getItrModel = async () => {
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  const rxid = settingsManager.getConfigValue(configuration.rxGuid);
  return await getModel(orderId || rxid);
};

const getCompareItrModel = async (orderId) => {
  return await getModel(orderId);
};

export const AOHSRequests = {
  getNiriFile,
  getItrModel,
  getCompareItrModel,
  getModelUrl,
  getNiriFilePath,
};
