import { Assets } from '@itero/assets-bff-sdk';
import { utils } from '../utils';

const getAssetSdk = () => {
  if (!utils) {
    return null;
  }
  const assetManagerUrl = utils.getAssetManagerUrl();
  if (!assetManagerUrl) {
    return null;
  }

  return new Assets(assetManagerUrl);
};

const getAsset = async (assetReferenceType) => {
  const assets = getAssetSdk();
  if (!assets) {
    return null;
  }
  const orderId = utils.getOrderId();
  const rxGuid = utils.getRxGuid();
  const requestModel = {
    assetReferenceType: assetReferenceType,
    assetReferenceId: orderId,
    rxId: rxGuid,
  };
  const accessToken = utils.getAccessToken();
  const isScanner = utils.isScannerHostEnv();
  const asset = await assets.getAssets(requestModel, accessToken, isScanner);
  return { arrayBuffer: () => asset };
};

export const assetManagerRequests = {
  getAsset,
};
