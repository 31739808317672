import { Vector3, MathUtils, Vector2 } from 'three';
import const_params from '../const_params';
import { projectPointToImage } from '../lumina.common.logic';

const {
  pix_dist_coef,
  caries_detection_score_amp,
  caries_detection_score_threshold,
  caries_detection_penalty_coefficient,
  max_dist_from_cam_to_img_cen_on_surf,
  angle_difference_penalty_coefficient,
  max_angle_difference_between_view_and_image,
  max_angle_difference_between_view_and_image_wide,
  angie_max_distance_between_pr_pt_and_cam_mm,
} = const_params;

const computePositionValueAndDirection = ({
  jawName,
  image_idx,
  intersect,
  view_direction,
  currentActiveJaw,
  images_meta_data,
  isuminaBestScoreAlgorithmAvaliable,
}) => {
  const intersect_pt = intersect.point;
  const currentImageData = images_meta_data[image_idx];
  const {
    scan_role,
    was_cam_projected,
    img_cen_on_surf_pt,
    camera_pt,
    camera_dir,
    rect_of_image,
    caries_detection_score,
    camera_to_pixel,
    K_vector,
    P_vector,
  } = currentImageData;

  if (scan_role !== jawName) return;

  // 1. Constraint: the distance between loupe position and image center projected on surface should not differ too much.
  // Although similar criteria is utilised below, they rely on the fact that projected image center is actually visible from
  // corresponding camera. However, it might happen that image center on surface is not visible from camera, but belongs to the
  // region of interest. This is due to the fact the we do not apply rasterization/ray tracing, while projection algorithm
  if (was_cam_projected) {
    const intersectPointToImgCenterVectorLength = new Vector3().subVectors(intersect_pt, img_cen_on_surf_pt).length();
    if (intersectPointToImgCenterVectorLength > max_dist_from_cam_to_img_cen_on_surf) return;
  } else {
    const intersectPointToCameraPointVectorLength = new Vector3().subVectors(intersect_pt, camera_pt).length();
    if (intersectPointToCameraPointVectorLength > angie_max_distance_between_pr_pt_and_cam_mm) return;
  }

  // 2. Constraint
  const cameraDirectionVector = new Vector3().copy(camera_dir);
  const angle_diff = cameraDirectionVector.angleTo(view_direction);

  if (angle_diff > MathUtils.degToRad(max_angle_difference_between_view_and_image)) return;

  // 3. Constraint: loupe should actually belong to the region of interest

  const projected2DPointOnImage = projectPointToImage(
    intersect,
    isuminaBestScoreAlgorithmAvaliable,
    currentActiveJaw[image_idx],
    camera_to_pixel,
    K_vector,
    P_vector
  );

  if (!rect_of_image.includesPoint(new Vector2(projected2DPointOnImage.x, projected2DPointOnImage.y))) return;

  // 4. Compute compliance coefficient with current view. For the second round select only those images that pass a predefined threshold
  const roi_center_pt = rect_of_image.center();
  const distance_px = new Vector2().subVectors(roi_center_pt, projected2DPointOnImage).length();

  const angle_penalty = angle_diff * angle_difference_penalty_coefficient;
  const pix_dist_penalty = distance_px * pix_dist_coef;

  const caries_detection_penalty =
    caries_detection_score < caries_detection_score_threshold
      ? caries_detection_penalty_coefficient
      : -caries_detection_score * caries_detection_score_amp;

  const penalty_value = angle_penalty + pix_dist_penalty + caries_detection_penalty;

  return {
    img_idx: image_idx,
    penalty: penalty_value,
    selected2DPointOnImage: projected2DPointOnImage,
  };
};

export const selectBestMatchImageByPenalty = (
  isuminaBestScoreAlgorithmAvaliable,
  view_direction,
  jawName,
  intersect,
  currentActiveJaw,
  images_meta_data
) => {
  // Image to be selected
  const image_to_show = { img_idx: -1, selected2DPointOnImage: new Vector2(0, 0) };

  // Part 1: compute general compliance coefficient
  let minImagePenalty = Number.MAX_VALUE;

  const angleDiffVariantsArr = [
    max_angle_difference_between_view_and_image,
    max_angle_difference_between_view_and_image_wide,
  ];

  for (let angleDiffVariants = 0; angleDiffVariants < angleDiffVariantsArr.length; ++angleDiffVariants) {
    for (let image_idx = 0; image_idx < currentActiveJaw.length; ++image_idx) {
      const positionValueAndDirection = computePositionValueAndDirection({
        jawName,
        image_idx,
        intersect,
        view_direction,
        currentActiveJaw,
        images_meta_data,
        isuminaBestScoreAlgorithmAvaliable,
        max_angle_difference_between_view_and_image: angleDiffVariantsArr[angleDiffVariants],
      });

      if (!positionValueAndDirection) continue;

      if (positionValueAndDirection.penalty < minImagePenalty) {
        image_to_show.img_idx = positionValueAndDirection.img_idx;
        image_to_show.penalty = positionValueAndDirection.penalty;
        image_to_show.selected2DPointOnImage = positionValueAndDirection.selected2DPointOnImage;
        minImagePenalty = positionValueAndDirection.penalty;
      }
    }

    if (image_to_show.img_idx !== -1) break;
  }

  return {
    img_idx: image_to_show.img_idx,
    image: currentActiveJaw[image_to_show.img_idx],
    selected2DPointOnImage: image_to_show.selected2DPointOnImage,
  };
};
